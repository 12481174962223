import * as React from "react"
import Layout from "../../components/layout/layout.en"
import Seo from "../../components/seo"
import { Link } from "gatsby"


const NotFoundPage = () => {
    return (
  <Layout>
    <Seo title="Error 401 - Authentication required" />
    <div className="container">
      <h1>Error 401 - Authentication required</h1>
      <p>The server couldn't verify that you're authorized to access this web page or resource.</p>
      <ul>
		<li>
            <Link to="/">
                Return to the home page
            </Link>
        </li> 
	   </ul> 
    </div>
  </Layout>
)
    }

export default NotFoundPage